.colorBox {
  padding: 10px;
  border-radius: 50px 0 0 50px;
}

.colorBox:hover {
  cursor: pointer;
}

.colorBoxSelected {
  padding: 10px;
  border-radius: 50px 0 0 50px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.14);
  background: #fff;
  margin: 8px 0;
}

.colorBoxSelected:hover {
  cursor: pointer;
}

.colorImg {
  height: 46px;
  width: 46px;
  margin-right: 8px;
  border-radius: 50px;
}

.colorImgSelected {
  height: 65px;
  width: 65px;
  margin-right: 8px;
  border-radius: 50%;
  border: 4px solid #00859a;
}

.colorTextName {
  font-size: 14px !important;
}
.colorTextNameSelected {
  font-size: 16px !important;
  color: #01859a;
  font-weight: 700 !important;
}
.colorTextCode {
  font-size: 14px !important;
}
.colorTextCodeSelected {
  font-size: 10px !important;
  color: #01859a;
  font-weight: 700 !important;
}


@media screen and (max-width: 768px) {
    .colorBoxSelected {
        background: transparent;
        box-shadow: initial;
    }
}