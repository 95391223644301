.productTitleContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.productImageContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
