.card {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  border-radius: 8px !important;
}

.iconCard {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.lineBoxContainer {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
.lineBoxItem {
  border: 1px solid #00859a;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
