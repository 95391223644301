.avatar {
  display: inline-flex;
  /* background-color: #e8a235 !important; */
  border-radius: 50%;
  width: 22rem !important;
  height: 22rem !important;
  overflow: visible !important;
}

.categoryContainer {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
}

.categoryBoxItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
}

.categoryItem {
  background-color: #ffffff;
  color: #000000;
  padding: 5px;
  border-radius: 50%;

  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
