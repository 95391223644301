.swiper {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: contain;
}

/* ----------------------- swiper center ----------------------- */
.swiper-default {
  background: #dbd9dc;
}

.swiper-default-button-prev {
  color: #ffffff;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background: linear-gradient(
    90deg,
    #2c2c2c -107.94%,
    rgba(44, 44, 44, 0) 76.64%
  );
  height: 100%;
  top: 0;
  left: 0;
  margin-top: 0;
  opacity: 0.9;
  padding: 0 20px;
}

.swiper-default-button-next {
  color: #ffffff;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  background: linear-gradient(
    270deg,
    #2c2c2c -107.94%,
    rgba(44, 44, 44, 0) 76.64%
  );
  height: 100%;
  top: 0;
  right: 0;
  margin-top: 0;
  opacity: 0.9;
  padding: 0 20px;
}

/* Estilizando os bullets */
.swiper-default-pagination .swiper-pagination-bullet {
  background-color: #ffffff;
  width: 15px;
  height: 15px;
  margin: 0 8px;
  border: 2px solid #4e3629;
  opacity: 0.9;
}

.swiper-default-pagination .swiper-pagination-bullet-active {
  background-color: #4e3629;
  border: 2px solid #4e3629;
}

/* ----------------------- swiper center ----------------------- */

.swiper-center-slide {
  object-fit: cover;
  background-color: #ffffff;
  filter: brightness(40%);
  border-radius: 8px;
  box-shadow: initial;
  top: 15px;
}
.swiper-center-slide.swiper-slide-active {
  top: 0;
  filter: brightness(100%);
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.14);
}

.swiper-center-slide img {
  height: 457px;
  width: 457px;
  max-width: 457px;
  max-height: 457px;
  box-shadow: initial;
}

.swiper-center-slide.swiper-slide-active img {
  height: 487px;
  width: 487px;
  max-width: 487px;
  max-height: 487px;
}

.swiper-center-button-prev {
  color: #ffffff;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-top: 0;
  opacity: 0.9;
  padding: 10px;
  background-color: #00859a;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  left: 20%;
}
.swiper-center-button-prev::after {
  font-size: 26px;
}

.swiper-center-button-next {
  color: #ffffff;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-top: 0;
  opacity: 0.9;
  padding: 10px;
  background-color: #00859a;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  right: 20%;
}

.swiper-center-button-next::after {
  font-size: 26px;
}

/* Estilizando os bullets */
.swiper-center-pagination .swiper-pagination-bullet {
  background-color: #9c9c9c;
  width: 41px;
  height: 10px;
  margin: 0 8px;
  border-radius: 15px;
  opacity: 0.9;
}

.swiper-center-pagination .swiper-pagination-bullet-active {
  width: 113px;
  background-color: #00859a;
}

@media screen and (max-width: 768px) {
  .swiper-center-slide img {
    height: 100%;
    width: 100%;
    max-width: none;
    max-height: none;
    box-shadow: initial;
  }

  .swiper-center-slide.swiper-slide-active img {
    height: 100%;
    width: 100%;
    max-width: none;
    max-height: none;
  }

  .swiper-center-button-prev {
    left: 0;
  }
  .swiper-center-button-next {
    right: 0;
  }
}
